<template>

    <div class="setmain">
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="人员在岗离岗检测时间">
                <a-input v-model="config_2.param" class="w200"/>
                <span class="ml20">单位：分钟</span>
                <a-button type="primary" class="ml40" @click="doclick()">保存</a-button>
            </a-form-model-item>
            <a-form-model-item label="试剂近效期提醒天数">
                <a-input v-model="config_3.param" class="w200"/>
                <span class="ml20">单位：自然天</span>
                <a-button type="primary" class="ml20" @click="doclick1()">保存</a-button>
            </a-form-model-item>
            <a-form-model-item label="是否启用样品设置">
                <a-radio-group name="radioGroup" :default-value="1" style="margin-left: 50px" v-model="config_4.status">
                    <a-radio :value="1">
                        是
                    </a-radio>
                    <a-radio :value="2">
                        否
                    </a-radio>
                </a-radio-group>
                <a-button type="primary" class="ml20" @click="doclick2()" style="margin-left: 150px">保存</a-button>
            </a-form-model-item>
        </a-form-model>

    </div>

</template>

<script>
    export default {
        data() {
            return {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
                config_2: {},
                config_3: {},
                config_4: {},
            };
        },
        mounted() {
            this.Get_config()
        },
        methods: {
            Get_config() {
                this.$sa0.post({
                    url: this.$api('Get_config'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.config_2 = response.data.config_2
                            this.config_3 = response.data.config_3
                            this.config_4 = response.data.config_4
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            doclick() {
                this.$sa0.post({
                    url: this.$api('Config'),
                    data: {
                        type: 2,
                        param: this.config_2.param
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_config()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            doclick1() {
                this.$sa0.post({
                    url: this.$api('Config'),
                    data: {
                        type: 3,
                        param: this.config_3.param
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_config()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            doclick2() {
                this.$sa0.post({
                    url: this.$api('Config'),
                    data: {
                        type: 4,
                        status: this.config_4.status
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_config()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


        }
    }
</script>

<style scoped>

    .setmain{ width: 800px; background: #f9f9f9; border: 1px dashed #eeeeee; position: absolute; top: 40%; left: 50%; transform: translate(-50%, -50%); padding: 50px}

    .w200{ width: 200px}

    .ml20{ margin-left: 20px}

    .ml40{ margin-left: 35px}

</style>
